import React from "react"
import { Link } from "gatsby"
import animatedIcon from "../assets/img/Contact-us-animation4.gif"
import animatedIconWhite from "../assets/img/Contact-us-animation-white.gif"
import SocialMediaIcons from "./SocialMediaIcons"
import { StaticImage } from "gatsby-plugin-image"

const Contact = () => {
  return (
    <section className="contact-us py-10 md:py-20 bg-[#414142]">
      <div className="container">
        <div className="flex justify-between items-center flex-wrap md:flex-nowrap">
          <ul>
            <li className="flex flex-col pb-[1.875rem] md:pb-[3.125rem]">
              <h3 className="font-blancomedium text-white pb-2">
                Inquires
                <span className="inline-block text-orangecol">.</span>
              </h3>
              <a
                href="mailto:sales@techuz.com"
                className="md:text-[1.5rem] text-xl font-gorditaregular underline leading-[1.875rem] text-white transition-all duration-500 hover:text-primary"
              >
                sales@techuz.com
              </a>
              <a
                href="mailto:hr@techuz.com"
                className="md:text-[1.5rem] text-xl font-gorditaregular underline leading-[1.875rem] text-white transition-all duration-500 hover:text-primary mt-[10px]"
              >
                hr@techuz.com
              </a>
            </li>
            <li className="flex flex-col pb-7">
              <h3 className="font-blancomedium text-white pb-2">
                Reach us at
                <span className="inline-block text-orangecol">.</span>
              </h3>
              <div className="flex items-center pb-2">
                <span className="bg-[#2A2A2D] w-9 h-9 flex item-center justify-center mr-[0.625rem] rounded-full items-center">
                  <StaticImage src="../assets/img/wp.png" alt="" />
                </span>
                <a
                  target="_blank"
                  href="https://wa.me/message/ZPEQ4QXOJTEDD1"
                  className="md:text-[1.125rem] text-base font-gorditaregular leading-[1.875rem] text-white transition-all duration-500 hover:text-primary"
                >
                  +91 95125 59090
                </a>
                <span className="text-sm font-gorditaregular text-white ml-1">( Sales )</span>
              </div>
              <div className="flex items-center">
                <span className="bg-[#2A2A2D] w-9 h-9 flex item-center justify-center mr-[0.625rem] rounded-full items-center">
                  <StaticImage src="../assets/img/wp.png" alt="" />
                </span>
                <a
                  href="tel:9512559091"
                  className="md:text-[1.125rem] text-base font-gorditaregular leading-[1.875rem] text-white transition-all duration-500 hover:text-primary"
                >
                  +91 95125 59091
                </a>
                <span className="text-sm font-gorditaregular text-white ml-1">( HR )</span>
              </div>
            </li>
            <li className="flex flex-col md:pt-[2.625rem] pt-0 pb-[1.875rem] md:pb-0">
              <SocialMediaIcons />
            </li>
          </ul>
          <div className="max-[567px]:w-full md:w-[440px] lg:w-[660px] h-[340px] max-md:h-[270px] contact-form leading-[2.375rem] pt-10 md:pt-14 pb-10 md:pb-[4.875rem] px-[1.875rem] rounded-[1.875rem] text-center lg:px-[3.125rem] relative z-10 bg-white-200 shadow-[0_9px_0_rgba(42,42,45,1)]">
            <h2 className="text-black text-2xl sm:text-[1.75rem] lg:text-[2.625rem] lg:leading-[3.375rem] pb-12 font-blancomedium text-center">
              Get the <i>real estimates</i> and <br /> <i>ideal solutions</i> for your project
              <span className="inline-block text-orangecol">.</span>
            </h2>
            <Link
              to="/contact-us/"
              className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl text-base leading-[1.313rem] rounded-3xl pl-6 py-1 pr-1 font-gorditamedium group ease-in duration-300"
            >
              Get an estimate
              <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-10 w-10 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                <img
                  src={animatedIconWhite}
                  alt=""
                  className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-100 visible group-hover:opacity-0 group-hover:invisible transition-all ease-in duration-300"
                />
                <img
                  src={animatedIcon}
                  alt=""
                  className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all ease-in duration-300"
                />
              </span>
            </Link>
            <StaticImage src="../assets/img/tech-bg-new.webp" alt="" className="absolute w-full h-full top-0 left-0 -z-10 rounded-[1.875rem] " />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
