import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import useToggleFaq from "../../../hooks/useToggleFaq"
import { StaticImage } from "gatsby-plugin-image"

const Faq = ({ faqData }) => {
  const {
    allReactPpcFaqJson: { faq },
  } = useStaticQuery(graphql`
    query {
      allReactPpcFaqJson {
        faq: nodes {
          key
          question
          answer
        }
      }
    }
  `)
  const { isExpanded, getToggleMeClass } = useToggleFaq()
  const allFaqs = faqData?.length ? faqData : faq;

  return (
    <section id="faq" className="lg:pt-[4.375rem] pt-10 lg:pb-20 pb-14 bg-[#F7F8F9]">
      <div className="container">
        <div className="flex justify-between flex-wrap md:flex-nowrap">
          <div className="md:w-[25.875rem] w-full md:pt-6 pt-0">
            <h2 className="text-black text-[1.9rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium">
              Frequently <br className="hidden md:block" />asked <em className="text-[#4BD965]">questions</em>
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h2>
          </div>
          <div className="md:w-[45.375rem] pl-0 w-full md:pl-12 md:border-l border-[rgba(255,255,255,0.17)]">
            {allFaqs.map(({ key, question, answer, listing, conclusion }, idx) => (
              <div
                key={`${key}-${idx}`}
                className={`${idx === allFaqs.length - 1 ? "" : "border-b border-[#000]"
                  } py-6`}
              >
                <span
                  onClick={() => {
                    getToggleMeClass(key)
                  }}
                  className="flex py- items-center justify-between md:text-lg text-base select-none font-gorditamedium text-black relative pr-[3.5rem] cursor-pointer"
                >
                  {question}
                  <span
                    id="showMore"
                    className={`absolute right-0 top-2/4 translate-y-[-50%] w-6 h-6 ${isExpanded[key]
                      ? " rotate-180"
                      : ""
                      } flex justify-center items-center cursor-pointer`}
                  >
                    <StaticImage src="../../../assets/img/arrow-down-black.webp" alt="" />
                  </span>
                </span>
                <p>{isExpanded ? isExpanded[key] : "ffff"}</p>
                <div
                  id={key}
                  className={`${isExpanded[key] ? "" : "hidden"}`}
                >
                  {answer.map((text, idx) => (
                    <React.Fragment key={`${text}-${idx}`}>
                      <p className="select-none text-[0.938rem] md:text-base leading-[1.563rem] md:leading-[1.625rem] pt-[0.625rem] md:pt-[18px]">
                        {text}
                      </p>
                    </React.Fragment>
                  ))}
                  {listing && (
                    <ul>
                      {listing.map((text, idx) => (
                        <li
                          key={`${idx}-${idx + 1}`}
                          className="select-none text-base leading-[1.69] w-full my-2 relative pl-4 before:content[''] before:absolute before:bg-orangecol before:w-2 before:h-2 before:rounded-full before:top-1/2 before:left-0 before:translate-y-[-50%]"
                        >
                          {text}
                        </li>
                      ))}
                    </ul>
                  )}
                  {conclusion && conclusion.map((text, idx) => (
                    <React.Fragment key={`${text}-${idx}`}>
                      <p className="select-none text-[0.938rem] md:text-base leading-[1.563rem] md:leading-[1.625rem] pt-[0.625rem] md:pt-[18px]">
                        {text}
                      </p>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
export default Faq
