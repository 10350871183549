import React from "react"
import { SwiperSlide } from "swiper/react"
import SwiperSlider from "./SwiperSlider"
import { StaticImage } from "gatsby-plugin-image"
import { useContextValues } from "../context/NavBarContext"
import { SwiperButtons } from "./SwiperButtons"

type propType = {
    bannerCount: 4 | 5
    sliderClassWeb?: string
    swiperButtons?: "chevron" | "chevronRounded" | "bullets"
}

const BannerSlider = (prop: propType) => {
    const { isMobile } = useContextValues()

    return (
        <>
            {isMobile
                ?
                <div className="banner-slider-inner overflow-hidden flex md:gap-[2rem] xl:gap-[5rem] flex-wrap items-end justify-center">
                    <SwiperSlider autoplay>
                        <SwiperSlide className="text-center">
                            <StaticImage placeholder="blurred" src="../assets/img/inner-banner-logo1.inline.svg" alt="Shell" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <StaticImage placeholder="blurred" src="../assets/img/inner-banner-logo2.inline.svg" alt="Countly" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <StaticImage placeholder="blurred" src="../assets/img/inner-banner-logo3.inline.svg" alt="Tutorac" />
                        </SwiperSlide>
                        {prop.bannerCount === 5 && <SwiperSlide className="text-center">
                            <StaticImage src="../assets/img/inner-banner-logo4.inline.svg" alt="Mr. Button" />
                        </SwiperSlide>}
                        <SwiperSlide className="text-center">
                            <StaticImage placeholder="blurred" src="../assets/img/inner-banner-logo5.inline.svg" alt="Schedash" />
                        </SwiperSlide>
                        {prop.swiperButtons && <SwiperButtons buttonType={prop.swiperButtons} totalRecords={prop.bannerCount} />}
                    </SwiperSlider>
                </div>
                :
                <div className={`banner-slider-inner flex gap-[2rem] xl:gap-[5rem] flex-wrap items-end justify-between ${prop.sliderClassWeb || ''}`}>
                    <figure className="banner-slide">
                        <StaticImage placeholder="blurred" src="../assets/img/inner-banner-logo1.inline.svg" alt="Shell" />
                    </figure>
                    <figure className="banner-slide">
                        <StaticImage placeholder="blurred" src="../assets/img/inner-banner-logo2.inline.svg" alt="Countly" />
                    </figure>
                    <figure className="banner-slide">
                        <StaticImage placeholder="blurred" src="../assets/img/inner-banner-logo3.inline.svg" alt="Tutorac" />
                    </figure>
                    {prop.bannerCount === 5 && <figure className="banner-slide">
                        <StaticImage src="../assets/img/inner-banner-logo4.inline.svg" alt="Mr. Button" />
                    </figure>}
                    <figure className="banner-slide">
                        <StaticImage placeholder="blurred" src="../assets/img/inner-banner-logo5.inline.svg" alt="Schedash" />
                    </figure>
                </div>
            }
        </>
    )
}

export default BannerSlider