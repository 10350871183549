import React from "react"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ArrowWhite from "../../assets/img/arrow-right.webp"



import { StaticImage } from "gatsby-plugin-image"

interface featuredImage extends IGatsbyImageData {
  publicURL: string
}
export interface ProjectsProps {
  idx: string
  title: string
  slug: string
  subTitle: string
  description: string
  clutchLink: string
  industry: string
  budget: string
  country: string
  featuredImage: featuredImage | undefined
  backgroundStyles: string
}

const PortfolioDetail = (project: ProjectsProps) => {
  const image = getImage(project.featuredImage!)
  return (
    <div className={`${project.backgroundStyles} flex flex-wrap justify-between md:flex-nowrap md:pl-10 pl-[1.875rem] md:pt-12 pt-7 md:pb-0 pb-10 rounded-2xl mb-10 md:mb[3.125rem] pr-[0] md:gap-[30px] lg:gap-0`}>
      <div className="md:max-w-[40%] lg:max-w-[calc(40%-2.5rem)] md:pr-0 pr-[1.875rem] md:mb-0 mb-[3.125rem] w-full flex flex-col">
        <div className="pb-10 flex items-center justify-between">
          <h3 className="font-blancomedium text-xl md:text-2xl max-sm:max-w-[150px]">
            {project.title}
            <span className="inline-block text-orangecol">.</span>
          </h3>
          {project.clutchLink && <Link rel="nofollow" target="_blank" to={project.clutchLink} className="inline-flex items-center bg-white-200 justify-start rounded-full px-[1.063rem] pt-[12px] pb-[13px] font-gorditamedium text-sm leading-none">
            <StaticImage
              src="../../assets/img/star.png"
              alt=""
              placeholder="blurred"
            />
            <span className="mr-2 ml-1 relative top-[2px] leading-[15.11px]">5.0/5</span>
            <StaticImage
              src="../../assets/img/cluch.png"
              alt=""
              placeholder="blurred"
            />
          </Link>}
        </div>

        <div className="flex flex-wrap items-center justify-between md:flex-nowrap md:pb-10 lg:pb-0">
          <div className="max-w-full w-full flex items-center flex-wrap">
            <h3 className="text-2xl md:text-[30px] lg:text-4xl font-blancomedium">
              {project.subTitle}
            </h3>
            <p className="text-base leading-[1.7rem] block pb-5 pt-5 md:pt-7">
              {project.description}
            </p>
            <ul className="flex flex-wrap mb-10 w-full">
              <li className="text-sm pr-3 mr-3 relative after:content-[''] after:w-1 after:h-1 after:bg-black after:rounded-full after:absolute after:top-[6px] after:right-0" >
                {project.industry}
              </li>
              <li className="text-sm pr-3 mr-3 relative after:content-[''] after:w-1 after:h-1 after:bg-black after:rounded-full after:absolute after:top-[6px] after:right-0" >
                {project.budget}
              </li>
              <li className="text-sm">
                {project.country}
              </li>
            </ul>
            <Link
              to={`/portfolio${project.slug}/`}
              className="inline-flex items-center bg-white-200 justify-start inline-white text-black text-base rounded-3xl pl-6 pt-[12px] pb-[10px] relative pr-[52px] leading-[1.313rem] font-gorditamedium group ease-in duration-300"
            >
              Project Details
              <span className="inline-flex bg-black justify-center items-center rounded-full ml-[0.625rem]  h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-black absolute right-[5px] top-[5px]">
                <img
                  src={ArrowWhite}
                  alt="ArrowRight"
                  className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                />
                <img
                  src={ArrowWhite}
                  alt="ArrowRight"
                  className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                />
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="md:max-w-[60%] lg:max-w-[calc(60%-2.5rem)] w-full flex flex-col justify-end items-center">
        <GatsbyImage
          image={image!}
          alt={`Techuz portfolio ${project.subTitle}`}
          className="mx-auto rounded-lg md:rounded-none"
        />
      </div>
    </div>
  )
}

export default PortfolioDetail
